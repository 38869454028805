import type { MappingData } from '~/types/mapping';

import { withQuery } from 'ufo';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const token = useCookie(AUTH.accessTokenKey, { readonly: true });
    const mapping = useMapping();

    if (mapping.data) return;
    const q = (mapping.data = to.query as unknown as MappingData);

    if (!q.redirect_uri || !q.full_name || !q.app_name || (!q.email && !q.phone_number)) return redir();

    if (!token.value) return navigateTo(withQuery('/authorize', { ...to.query, mapping: 'true' }));

    const { data, error } = await mapping.checkValidity();
    if (!data?.valid) return redir(error);
  }
});

function redir(statusMessage = 'Unknown Account Conenction Data Provided') {
  return navigateTo(withQuery('/authorize/error', { statusMessage }));
}
